import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import axios from 'axios';
import { socket } from 'config/socket';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, useMediaQuery, useTheme, Box } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CustomizeChat from 'components/CustomizeChat';
import Message from 'components/Message';
import StarsIcon from 'svg/StarsIcon';
import EmojiIcon from 'svg/EmojiIcon';
import { ADD_NEW_MESSAGE_URL, GET_ALL_MESSAGES_URL } from 'helpers/constants';
import { LocalStorage } from 'utils/LocalStorage';
import {
  SendIconWrapper,
  StyledChatArrow,
  StyledChatBoxBottom,
  StyledChatBoxTop,
  StyledChatBoxWrapper,
  StyledChatMessageInput,
  StyledCount,
  StyledEmojiPickerContainer,
  StyledHeaderBtn,
  StyledHeaderBtnText,
  StyledHeaderTitle,
  StyledMessengerContainer,
  StyledMessengerHeader,
} from './styles';
import ArrowButtonIcon from 'svg/ArrowButtonIcon';
import { IMessage } from 'types/chat';
import SendIcon from 'svg/SendIcon';
import { Color } from 'helpers/themeStyles';

export default function Messenger() {
  const { t } = useTranslation();
  const userLogin = LocalStorage.getUserChatData();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [arrivalMessage, setArrivalMessage] = useState<IMessage>({
    nickname: '',
    createdAt: '',
    _id: '',
    sender: '',
    text: '',
  });
  const [openCustomizeMenu, setOpenCustomizeMenu] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [countUnreadMessage, setCountUnreadMessage] = useState(0);
  const scrollMessageRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [openChat, setOpenChat] = useState<boolean>(true);

  function onEmojiClick(emojiData: EmojiClickData, event: MouseEvent) {
    setNewMessage((prevMessage) => prevMessage + emojiData.emoji);
    (inputRef.current?.children[0].children[0] as HTMLInputElement)?.focus();
    setShowEmojiPicker(false);
  }

  const scrollIntoView = () => {
    setCountUnreadMessage(0);
    scrollMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getAllMessage = async () => {
    try {
      const res = await axios.get(GET_ALL_MESSAGES_URL);
      setMessages(res.data);
      await scrollIntoView();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllMessage();
    socket.on('getMessage', (data) => {
      setArrivalMessage({
        sender: data.sender,
        text: data.text,
        nickname: data.nickname,
        createdAt: new Date().toISOString(),
      });
    });
  }, []);

  const scrollRef = useBottomScrollListener(() => {
    setCountUnreadMessage(0);
  });

  useEffect(() => {
    arrivalMessage && setMessages((prev: any) => [...prev, arrivalMessage]);
    if (arrivalMessage?.sender !== userLogin?.id && messages.length > 4) {
      setCountUnreadMessage((prevState) => prevState + 1);
    }
  }, [arrivalMessage]);

  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  useEffect(() => {
    if (!newMessage) {
      return;
    }
    const message = {
      sender: userLogin.id,
      text: newMessage,
      nickname: userLogin.nickname,
    };
    const timeoutId = setTimeout(async () => {
      socket.emit('sendMessage', message);
      try {
        const res = await axios.post(ADD_NEW_MESSAGE_URL, message);
        setMessages([...messages, res.data]);
        setNewMessage('');
        scrollIntoView();
      } catch (err) {
        console.error(err);
      }
    }, 700);
    setIsSubmitted(false);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isSubmitted]);

  const handlerEvent = async (e: React.KeyboardEvent | React.MouseEvent) => {
    e.preventDefault();
    if (!userLogin) {
      setOpenCustomizeMenu(true);
    } else {
      setOpenCustomizeMenu(false);
      await handleSubmit();
    }
  };

  const onKeyDownHandler = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handlerEvent(e);
    }
  };

  const onClickHandler = async (e: React.MouseEvent) => {
    if (e.currentTarget.nodeName === 'DIV') {
      handlerEvent(e);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', () => {
      setShowEmojiPicker(false);
    });
  });

  return (
    <>
      <StyledMessengerContainer
        style={{
          height: openChat ? '25%' : '7%',
          minHeight: openChat ? '25%' : '7%',
          position: openChat ? 'static' : 'fixed',
          bottom: 0,
        }}
      >
        <StyledChatBoxWrapper>
          <StyledMessengerHeader>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '15px',
              }}
            >
              <StyledHeaderTitle>{t('planetexChat')}</StyledHeaderTitle>
              <StyledHeaderBtn onClick={() => setOpenCustomizeMenu(true)}>
                <StarsIcon />
                <StyledHeaderBtnText>{t('edit')}</StyledHeaderBtnText>
              </StyledHeaderBtn>
            </div>
            {!isMobile && (
              <Box
                onClick={() => setOpenChat(!openChat)}
                width={24}
                height={24}
              >
                {openChat ? (
                  <ExpandMore
                    sx={{
                      color: Color.WHITE,
                      cursor: 'pointer',
                      width: '40px',
                      height: 'auto',
                    }}
                  />
                ) : (
                  <ExpandLess
                    sx={{
                      color: Color.WHITE,
                      cursor: 'pointer',
                      width: '40px',
                      height: 'auto',
                    }}
                  />
                )}
              </Box>
            )}
          </StyledMessengerHeader>
          <StyledChatBoxTop
            ref={scrollRef}
            style={{ display: openChat ? 'block' : 'none' }}
          >
            {messages && messages.length > 0 && Array.isArray(messages)
              ? messages?.map((m, index) => {
                  return (
                    <div
                      key={index}
                      ref={scrollMessageRef}
                    >
                      <Message
                        message={m}
                        own={m?.sender === userLogin?.id}
                      />
                    </div>
                  );
                })
              : ''}
          </StyledChatBoxTop>
          <StyledChatBoxBottom style={{ display: openChat ? 'flex' : 'none' }}>
            <StyledChatMessageInput
              ref={inputRef}
              fullWidth
              multiline
              maxRows={1}
              placeholder='Your message...'
              onChange={(e) => {
                const value = e.target.value;
                if (value.match(/^[ ]+$/)) {
                  setNewMessage('');
                } else {
                  setNewMessage(value);
                }
              }}
              onKeyDown={(e) => onKeyDownHandler(e)}
              value={newMessage}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position='end'
                    sx={{
                      cursor: 'pointer',
                      paddingRight: isMobile ? '42px' : '',
                    }}
                    onClick={(e) => {
                      setShowEmojiPicker((prev) => !prev);
                      e.stopPropagation();
                    }}
                  >
                    <EmojiIcon />
                  </InputAdornment>
                ),
              }}
            />
            {isMobile && (
              <SendIconWrapper onClick={(e) => onClickHandler(e)}>
                <SendIcon />
              </SendIconWrapper>
            )}
            {showEmojiPicker && (
              <StyledEmojiPickerContainer onClick={(e) => e.stopPropagation()}>
                <EmojiPicker
                  onEmojiClick={onEmojiClick}
                  theme={Theme.DARK}
                  width={250}
                />
              </StyledEmojiPickerContainer>
            )}
          </StyledChatBoxBottom>
        </StyledChatBoxWrapper>
        {countUnreadMessage > 0 && messages.length > 0 && (
          <StyledChatArrow onClick={scrollIntoView}>
            {ArrowButtonIcon()}
            <StyledCount>{countUnreadMessage}</StyledCount>
          </StyledChatArrow>
        )}
      </StyledMessengerContainer>
      {openCustomizeMenu && (
        <CustomizeChat
          openCustomizeMenu={openCustomizeMenu}
          setOpenCustomizeMenu={setOpenCustomizeMenu}
        />
      )}
    </>
  );
}
