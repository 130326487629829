import { Close, ReportProblemOutlined } from '@mui/icons-material';
import {
  Alert,
  ClickAwayListener,
  Grow,
  IconButton,
  Popper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DEXToken, getLinkForWallet, utils } from '@omisoftnet/game-dex-sdk';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalStorage } from 'utils/LocalStorage';
import {
  useAccount,
  useBalance,
  useDisconnect,
  useEnsName,
  useWaitForTransactionReceipt,
} from 'wagmi';

import { useAsUsd } from '@hooks/useAsUsd';
import { BigNumber } from 'ethers';
import formatTokenBalance from 'helpers/formatTokenBalance';
import { formatWalletKey } from 'helpers/formatWalletKey';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandLess, ExpandMore } from 'shared/ExpandMoreAndLess';
import {
  errorNetworkSelector,
  selectedNetworkSelector,
} from 'state/network/selectors';
import { setNetworkError } from 'state/network/slice';
import { transactionHashSelector } from 'state/transactions/selectors';
import { setIsOpen } from 'state/walletconnect/slice';
import AvatarIcon from 'svg/AvatarIcon';
import CopyIcon from 'svg/CopyIcon';
import ExploreIcon from 'svg/ExploreIcon';
import LogoutIcon from 'svg/LogoutIcon';
import {
  AppBarButton,
  AppBarText,
  AvatarContainer,
  ErrorText,
  StyledNetworkErrorSnackbar,
  StyledNetworkErrorSubtitle,
  StyledNetworkErrorTitle,
  StyledPaper,
  StyledSnackbar,
  UserButton,
} from './styles';

const WalletPopup = ({ isConnectedProp }: { isConnectedProp: boolean }) => {
  const dispatch = useDispatch();
  const { address, isConnected, chain } = useAccount();
  const { disconnect } = useDisconnect();
  const { data: ensName } = useEnsName({ address, chainId: chain?.id }); // ENS name its like domians but for blockchains, if user have ens better display ensName than wallet address @Danylo
  const { data: balance } = useBalance({
    address,
  });
  const [open, setOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [usdBalance, setUsdBalance] = useState<string>('');
  const anchorRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const transactionHash = useSelector(transactionHashSelector);
  const networkError = useSelector(errorNetworkSelector);
  const selectedNetwork = useSelector(selectedNetworkSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const { getValueInUsd } = useAsUsd();
  const { isLoading } = useWaitForTransactionReceipt({
    chainId: chain?.id,
    hash: transactionHash,
  });
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  async function getUSDBalance() {
    if (balance?.value) {
      setUsdBalance('');
      const token = DEXToken.default(chain?.id);
      token.amount = BigNumber.from(balance!.value);
      const usdBalance = await getValueInUsd({
        token: token,
        value: utils.formatUnits(token.amount, token.decimals),
      });
      setUsdBalance(usdBalance ?? '0');
      return usdBalance;
    }
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      getUSDBalance();
    }, 200);
    return () => clearTimeout(getData);
  }, [balance, chain]);

  useEffect(() => {}, [balance]);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const logout = () => {
    setOpen(() => {
      disconnect();
      localStorage.removeItem('currentWalletAddress');
      return false;
    });
    LocalStorage.clearToken();
    dispatch(setNetworkError(false));
  };
  return (
    <>
      <div ref={anchorRef}>
        {isConnected ? (
          <UserButton
            onClick={handleToggle}
            endIcon={
              isLoading || networkError ? null : open ? (
                <ExpandLess style={{ marginRight: '8px' }} />
              ) : (
                <ExpandMore style={{ marginRight: '8px' }} />
              )
            }
            sx={{
              border: open ? Border.ACCENT_BORDER : Border.TRANSPARENT_BORDER,
              backgroundImage: !open
                ? `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`
                : null,
              backgroundOrigin: !open ? 'border-box' : null,
              backgroundClip: !open ? 'padding-box, border-box' : null,
              padding: !isLoading ? '8px' : '0 24px',
            }}
          >
            {networkError ? (
              <>
                <ReportProblemOutlined sx={{ pl: 2, color: Color.RED }} />
                <ErrorText>{t('error')}</ErrorText>
              </>
            ) : isLoading ? (
              <AppBarText>{t('loading')}...</AppBarText>
            ) : (
              <>
                <AvatarContainer>
                  <AvatarIcon />
                </AvatarContainer>
                <AppBarText>
                  {ensName
                    ? ensName.toLowerCase()
                    : address
                    ? formatWalletKey(address)
                    : ''}
                </AppBarText>
              </>
            )}
          </UserButton>
        ) : (
          <AppBarButton
            variant='outlined'
            color='inherit'
            onClick={() => {
              dispatch(setIsOpen(true));
            }}
          >
            {t('connectWallet')}
          </AppBarButton>
        )}
      </div>
      <div style={{ position: 'static' }}>
        <Popper
          open={isConnected ? open : false}
          anchorEl={anchorRef.current}
          placement='bottom-start'
          transition
          disablePortal
          onReset={undefined}
          onResetCapture={undefined}
          sx={{ display: 'flex' }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transform: !isMobile ? 'translate3d(25px, -6px, 0px)' : '',
              }}
            >
              <StyledPaper
                sx={{
                  '&.MuiPaper-root': {
                    mr: !isMobile ? 3 : '',
                    mt: !isMobile ? 2.5 : 1,
                  },
                }}
              >
                <StyledSnackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={openNotification}
                  autoHideDuration={1500}
                  onClose={() => setOpenNotification(false)}
                >
                  <Alert
                    onClose={(e) => {
                      e.stopPropagation();
                      setOpenNotification(false);
                    }}
                  >
                    {`${t('copied')}!`}
                  </Alert>
                </StyledSnackbar>
                <ClickAwayListener onClickAway={handleClose}>
                  <Stack direction='column'>
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Stack
                        direction='row'
                        alignItems='center'
                        gap='6px'
                      >
                        <AvatarContainer sx={{ width: 32, height: 32 }}>
                          <AvatarIcon />
                        </AvatarContainer>
                        <Typography
                          fontSize={15}
                          fontFamily={FontFamily.INTER}
                          fontWeight={700}
                        >
                          {isConnected
                            ? ensName
                              ? ensName.toLowerCase()
                              : formatWalletKey(address!)
                            : ''}
                        </Typography>
                      </Stack>
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        gap='12px'
                      >
                        <Tooltip
                          title={
                            <Typography
                              fontSize={11}
                              sx={{ color: Color.TEXT_OPACITY_LIGHT }}
                            >
                              {t('copy')}
                            </Typography>
                          }
                          placement='bottom'
                          components={{ Tooltip: Stack }}
                        >
                          <IconButton
                            sx={{
                              p: '7px',
                              background: Color.BORDER_WHITE_EXTRA_OPACITY,
                              borderRadius: BorderRadius.SMALL,
                            }}
                            onClick={() => {
                              navigator.clipboard.writeText(address!);
                              setOpenNotification(true);
                            }}
                          >
                            <CopyIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={
                            <Typography
                              fontSize={11}
                              sx={{
                                color: Color.TEXT_OPACITY_LIGHT,
                                borderRadius: BorderRadius.SMALL,
                              }}
                            >
                              {t('explore')}
                            </Typography>
                          }
                          placement='bottom'
                          components={{ Tooltip: Stack }}
                        >
                          <IconButton
                            sx={{
                              p: '7px',
                              background: Color.BORDER_WHITE_EXTRA_OPACITY,
                              borderRadius: BorderRadius.SMALL,
                            }}
                            onClick={() => {
                              window.open(
                                getLinkForWallet(chain?.id!, address!)
                              );
                            }}
                          >
                            <ExploreIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={
                            <Typography
                              fontSize={11}
                              sx={{ color: Color.TEXT_OPACITY_LIGHT }}
                            >
                              {t('disconnect')}
                            </Typography>
                          }
                          placement='bottom'
                          components={{ Tooltip: Stack }}
                        >
                          <IconButton
                            sx={{
                              p: '7px',
                              background: Color.BORDER_WHITE_EXTRA_OPACITY,
                              borderRadius: BorderRadius.SMALL,
                            }}
                            onClick={logout}
                          >
                            <LogoutIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                    <Stack
                      direction='column'
                      alignItems='center'
                      marginTop={4}
                      marginBottom={2}
                    >
                      <Typography
                        variant='h4'
                        sx={{
                          fontFamily: FontFamily.ZENDOTS,
                          fontWeight: 400,
                          fontSize: TextSize.LARGE,
                          mb: 1,
                          textAlign: 'center',
                        }}
                      >
                        {balance &&
                          chain &&
                          formatTokenBalance(
                            balance.value,
                            chain?.nativeCurrency?.decimals
                          )}{' '}
                        {chain?.nativeCurrency?.symbol}
                      </Typography>
                      <Typography
                        variant='h6'
                        sx={{
                          fontFamily: FontFamily.INTER,
                          fontWeight: 400,
                          fontSize: TextSize.NORMAL,
                          color: Color.WHITE_OPACITY_LIGHT,
                        }}
                      >
                        ${usdBalance ? usdBalance.slice(0, 6) : '0.0'} USD
                      </Typography>
                    </Stack>
                  </Stack>
                </ClickAwayListener>
              </StyledPaper>
            </Grow>
          )}
        </Popper>
      </div>
      {chain?.id && (
        <StyledNetworkErrorSnackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={networkError}
          message={
            <>
              <Stack
                direction='row'
                alignItems='center'
                mb={2}
              >
                <ReportProblemOutlined
                  sx={{
                    mr: 1,
                    fontSize: TextSize.MEDIUM,
                    color: Color.RED,
                  }}
                />
                <StyledNetworkErrorTitle>
                  {t('failedToSwitchNetwork')}
                </StyledNetworkErrorTitle>
                <Close
                  sx={{
                    ml: 'auto',
                    cursor: 'pointer',
                    fontSize: TextSize.MEDIUM,
                  }}
                  onClick={() => dispatch(setNetworkError(false))}
                />
              </Stack>
              <StyledNetworkErrorSubtitle>
                {t('toUseDexSwitchNetwork', {
                  networkName: selectedNetwork,
                })}
              </StyledNetworkErrorSubtitle>
            </>
          }
        />
      )}
    </>
  );
};

export default WalletPopup;
