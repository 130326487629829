import { API_ROUTES } from 'config/apiRoutes';

export enum BTN_TITLE {
  SAVE = 'Save',
  CANCEL = 'Cancel',
  CREATE = 'Create new',
  EDIT = 'Edit',
}

export enum INPUT_LABEL {
  TITLE = 'Title',
  STATUS = 'Status',
  SHORT_DESCRIPTION = 'Short Description',
  TOPIC = 'Topic',
  IMAGE = 'Image',
  TAGS = 'Tags',
  IMAGE_ALT = 'Image Alt',
  SEO_TITLE = 'Seo Title',
  SEO_KEYWORD = 'Seo Keyword',
  SEO_DESCRIPTION = 'Seo Description',
  URL = 'Url',
  ADDTAG = 'Add tag',
  SIMILAR_ARTICLES = 'Similar Articles',
}

export enum TOPIC_ACTIONS {
  EDIT = 'edit topic',
  ADD = 'create new topic',
  URL_EDIT = 'edit',
  URL_ADD = 'add',
  SWAP = 'Swap',
  POOL = 'Pool',
}
export enum TOPICS_BUTTON {
  POOL = '/pool',
}
export enum STATUS_SELECT {
  ALL = 'All',
  PUBLISHED = 'Published',
  DRAFT = 'Draft',
  ARCHIVED = 'Archived',
}
export enum STATUS_TYPES_ENUM {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
}

export enum LANGUAGES_TYPES_ENUM {
  ENGLISH = 'en',
  UKRAINIAN = 'uk',
}

export enum LANGUAGES_FILTER_ENUM {
  All = 'All Languages',
  ENGLISH = 'English',
  UKRAINIAN = 'Ukrainian',
}

export const BASE_URL = process.env.REACT_APP_URL;

export enum LoadingResultsT {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum Messages {
  errorPassword = 'Sorry, failed ',
}

export enum UrlStyles {
  ITEM_HEIGHT = 48,
  ITEM_PADDING_TOP = 8,
}

export enum RoleUsers {
  ADMIN = 'Administrator',
  USER = 'User',
  MODERATOR = 'Moderator',
}
export enum LongMenuConfig {
  EDIT = 'Edit',
  RESET_PASSWORD = 'Reset Password',
  DEACTIVATE = 'Deactivate',
}

export enum MAX_TICK {
  MAX_TICK = 887220,
}

export enum MIN_TICK {
  MIN_TICK = -887220,
}

export const SLIPPAGETOLERANCE: string[] = ['0.1', '0.5', '1.0'];

export const SOCKET_IO_SERVER = 'https://api.sierraswap.com/';
export const GET_ALL_MESSAGES_URL =
  BASE_URL + API_ROUTES.MESSAGES.GET_ALL_MESSAGES;

export const ADD_NEW_MESSAGE_URL =
  BASE_URL + API_ROUTES.MESSAGES.ADD_NEW_MESSAGE;

export const CHAT_LOGIN_URL = BASE_URL + API_ROUTES.CHAT_LOGIN.ENTER_THE_CHAT;

export const TRANSACTION_DEADLINE_MAX_VALUE = 4320;

export const SLIPPAGE_TOLERANCE_LAST_VALID_VALUE = 50;

export const TAWK_TO_PROPERTY_ID = '638d0f7cdaff0e1306dae2cd';

export const TAWK_TO_WIDGET_ID = '1gjfg8vvt';

export const ADD_TRANSACTION_URL =
  BASE_URL + API_ROUTES.TRANSACTIONS.ADD_TRANSACTION;
export const Monthes = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const LAUNCHPAD_FACTORY_BSC =
  '0x427bF5b37357632377eCbEC9de3626C71A5396c1';

export const LAUNCHPAD_FACTORY_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'pad',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'salt',
        type: 'bytes32',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    name: 'Deployed',
    type: 'event',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'token',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'factory',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'payableToken',
            type: 'address',
          },
          {
            internalType: 'bytes32',
            name: 'salt',
            type: 'bytes32',
          },
          {
            internalType: 'uint256',
            name: 'toSuccessAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'totalEndTimestamp',
            type: 'uint256',
          },
        ],
        internalType: 'struct ILaunchPad.LaunchPadOptions',
        name: '_options',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'goal',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'minPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'start',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'whitelist',
            type: 'bool',
          },
          {
            internalType: 'bytes32',
            name: 'merkleRoot',
            type: 'bytes32',
          },
        ],
        internalType: 'struct ILaunchPad.Stage[]',
        name: '_stages',
        type: 'tuple[]',
      },
      {
        components: [
          {
            internalType: 'string',
            name: 'name',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'symbol',
            type: 'string',
          },
        ],
        internalType: 'struct GenericToken.GenericTokenOptions',
        name: '_tokenOptions',
        type: 'tuple',
      },
    ],
    name: 'deploy',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export const LAUNCHPAD_ABI = [
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'token',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'factory',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'payableToken',
            type: 'address',
          },
          {
            internalType: 'bytes32',
            name: 'salt',
            type: 'bytes32',
          },
          {
            internalType: 'uint256',
            name: 'toSuccessAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'totalEndTimestamp',
            type: 'uint256',
          },
        ],
        internalType: 'struct ILaunchPad.LaunchPadOptions',
        name: '_options',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'goal',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'minPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'start',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'whitelist',
            type: 'bool',
          },
          {
            internalType: 'bytes32',
            name: 'merkleRoot',
            type: 'bytes32',
          },
        ],
        internalType: 'struct ILaunchPad.Stage[]',
        name: '_stages',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'liquidity',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount0',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount1',
        type: 'uint256',
      },
    ],
    name: 'AddLiquidity',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'Bought',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'goal',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'minPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'start',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'whitelist',
            type: 'bool',
          },
          {
            internalType: 'bytes32',
            name: 'merkleRoot',
            type: 'bytes32',
          },
        ],
        indexed: false,
        internalType: 'struct ILaunchPad.Stage',
        name: 'stage',
        type: 'tuple',
      },
    ],
    name: 'Raised',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'bought',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        internalType: 'bytes32[]',
        name: 'merkleProof',
        type: 'bytes32[]',
      },
    ],
    name: 'buy',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'buy',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'claim',
    outputs: [
      {
        internalType: 'uint256',
        name: 'amount1Desired',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'currentStage',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'goal',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'minPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'start',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'whitelist',
            type: 'bool',
          },
          {
            internalType: 'bytes32',
            name: 'merkleRoot',
            type: 'bytes32',
          },
        ],
        internalType: 'struct ILaunchPad.Stage',
        name: 'stage',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'options',
    outputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'factory',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'payableToken',
        type: 'address',
      },
      {
        internalType: 'bytes32',
        name: 'salt',
        type: 'bytes32',
      },
      {
        internalType: 'uint256',
        name: 'toSuccessAmount',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'totalEndTimestamp',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'stages',
    outputs: [
      {
        internalType: 'uint256',
        name: 'price',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'goal',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'minPerWallet',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'maxPerWallet',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'start',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'end',
        type: 'uint256',
      },
      {
        internalType: 'bool',
        name: 'whitelist',
        type: 'bool',
      },
      {
        internalType: 'bytes32',
        name: 'merkleRoot',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalBought',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export const RANGE_OF_TICK_SPACING = 30;

export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';

export const LAUNCHPAD_COMMISSION = '0.02'; // 2%
