import { useMediaQuery, useTheme } from '@mui/material';
import { fetchData } from 'config/api';
import React, { useState, useEffect } from 'react';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';

import {
  CardWrap,
  PriceText,
  RowWrap,
  TitleStyled,
  TitleWrapper,
} from 'components/PopularTokenCard/styles';
import { Color } from 'helpers/themeStyles';
import ArrowGreen from 'svg/ArrowGreen';
import ArrowRed from 'svg/ArrowRed';

interface TokenDayData {
  date: string;
  close: number;
  priceUSD: number;
}

interface HotTokenData {
  name: string;
  address: string;
  logoURI: string;
}

const TokenChart: React.FC<{ tokenAddress: string }> = ({ tokenAddress }) => {
  const [theGraphData, setTheGraphData] = useState<TokenDayData[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  useEffect(() => {
    const fetchData = async () => {
      const query = `
        query MyQuery {
          tokenDayDatas(
            orderBy: date,
            orderDirection: desc,
            where: {token: "${tokenAddress}"}
          ) {
          token {
          name 
          }
            date
            priceUSD
          }
        }
      `;

      const response = await fetch(
        'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ query }),
        }
      );
      console.log('response', response);
      const { data } = await response.json();
      data.tokenDayDatas.sort(
        (
          a: { date: string | number | Date },
          b: { date: string | number | Date }
        ) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        }
      );
      setTheGraphData(data.tokenDayDatas);
    };

    fetchData();
  }, [tokenAddress]);

  const priceChangePercent =
    theGraphData.length > 0
      ? Number(
          (
            ((theGraphData[theGraphData.length - 1].priceUSD -
              theGraphData[0].priceUSD) /
              theGraphData[0].priceUSD) *
            100
          ).toFixed(2)
        )
      : 0;

  return (
    <>
      <PriceText>
        ${theGraphData.length && Number(theGraphData[0]?.priceUSD).toFixed(2)}
      </PriceText>
      <RowWrap>
        {priceChangePercent > 0 ? <ArrowGreen /> : <ArrowRed />}
        {priceChangePercent}%
      </RowWrap>
      <ResponsiveContainer height={50}>
        <AreaChart
          height={50}
          data={theGraphData}
        >
          <defs>
            <linearGradient
              id='gradient'
              x1='0'
              y1='0'
              x2='0'
              y2='1'
            >
              <stop
                offset='5%'
                stopColor={priceChangePercent > 0 ? Color.GREEN : Color.RED}
                stopOpacity={0.8}
              />
              <stop
                offset='95%'
                stopColor={priceChangePercent > 0 ? Color.GREEN : Color.RED}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <Area
            type='monotone'
            dataKey='priceUSD'
            stroke={priceChangePercent > 0 ? Color.GREEN : Color.RED}
            fill='url(#gradient)'
            dot={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

const PopularTokenCard: React.FC = () => {
  const [hotTokenData, setHotTokenData] = useState<HotTokenData[]>([]);

  useEffect(() => {
    try {
      (async () => {
        const { data } = await fetchData('tokens/hot/');
        setHotTokenData(data);
      })();
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <>
      {Array.isArray(hotTokenData) &&
        hotTokenData.length > 0 &&
        hotTokenData.map(({ address, name, logoURI }) => (
          <CardWrap>
            <TitleWrapper>
              <img
                src={logoURI}
                alt={name}
              />
              <TitleStyled>{name}</TitleStyled>
            </TitleWrapper>
            <TokenChart tokenAddress={address} />
          </CardWrap>
        ))}
    </>
  );
};

export default PopularTokenCard;
